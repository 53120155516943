<template>
  <div class="signin-background">
    <div class="spacer-1"></div>
    <div class="middle">
      <v-form ref="form" v-model="valid">
        <img src="@/assets/FireScoutLogo_white.svg" width="180px" style="margin: 45px 0 23px" />
        <div class="text-error" :style="{ visibility: isLoginFailed ? 'visible' : 'hidden' }">Incorrect ID or Password</div>
        <Input
          v-model="username"
          ref="username"
          label="ID"
          @input="isLoginFailed = false"
          placeholder="ID"
          :error="isLoginFailed"
          :rules="rules.id"
          style="min-width: 300px; margin-bottom: 44px"
        ></Input>
        <Input
          v-model="password"
          type="Password"
          ref="password"
          label="Password"
          @input="isLoginFailed = false"
          placeholder="Password"
          :error="isLoginFailed"
          :rules="rules.password"
          style="min-width: 300px; margin-bottom: 25px"
        ></Input>
        <div class="d-flex align-center align-self-end">
          <div class="text-keep-login">Keep me logged in</div>
          <ToggleLogin v-model="keepLogin" @input="onChangeKeepLogin" style="margin-right: 40px"></ToggleLogin>
        </div>
        <button height="36" v-ripple type="submit" @click.prevent="onClickLoginButton" class="button">Login</button>
      </v-form>
    </div>
    <div class="spacer-1"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import ToggleLogin from '@common/signin/ToggleLogin'
import Input from '@common/signin/Input'
import Footer from '@common/signin/Footer'
import rules from '@/rules'
import { auth } from '@/api'
import RotuerMixin from '@/mixin/router.mixin'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [RotuerMixin, Theme],
  components: { ToggleLogin, Input, Footer },
  data() {
    return {
      rules: rules,
      valid: true,
      username: '',
      password: '',
      isLoginFailed: false,
      keepLogin: true,
    }
  },
  async created() {
    if (auth.isAuth()) {
      this.$router.replace('/')
    }
    auth.keepLogin(true)
  },
  methods: {
    onChangeKeepLogin(e) {
      auth.keepLogin(e)
    },
    async onClickLoginButton() {
      // iphone의 경우 키체인에 의한 자동완성의 경우 아무런 이벤트가 발생되지 않기 때문에 강제로 validate를 작동시켜야 함
      const validUsername = this.$refs.username.validate()
      const validPassword = this.$refs.password.validate()
      if (validUsername && validPassword) {
        try {
          this.isLoginFailed = false
          await this.$store.dispatch('login', { username: this.username, password: this.password })
          this.$gtag.customMap({ 'User ID': this.username })
          this.$vuetify.theme.dark = false
        } catch (e) {
          this.isLoginFailed = true
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.signin-background {
  display: flex;
  flex-direction: column;
  background-color: #3b77ff;
  background-image: url('../../../assets/group 4023.jpg');
  height: 100%;
  overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .icon-logo {
    width: 70px;
    margin-top: 20px;
    margin-left: 30px;
  }
}

.middle {
  display: inline-flex;
  flex-direction: column;
  margin-right: 120px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    width: 380px;
    height: 427px;
    background-color: #1b1c22;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
  }

  .text-error {
    color: #f9423a;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 37px;
  }

  .text-keep-login {
    color: rgba(255, 255, 255, 0.87);
    font-size: 12px;
    margin-right: 8px;
  }

  .button {
    background: #f9423a;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    width: 300px;
    height: 44px;
    margin-top: 15px;
  }
}

.divider-container {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  .divider-line {
    height: 1px;
    width: 96px;
    background-color: rgba(255, 255, 255, 0.38);
  }

  .divider-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 13px;
  }
}
</style>
