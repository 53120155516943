<template>
  <div style="position: relative">
    <label v-if="label">{{ label }}</label>
    <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
    <input
      :type="type"
      :placeholder="placeholder"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      :class="{ empty, disabled, 'error-status': erroFlag }"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focus: false,
      text: this.value,
      erroFlag: this.error,
      errorMessage: '',
    }
  },
  computed: {
    empty() {
      return !this.text.length
    },
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
  },
  watch: {
    value(val) {
      this.text = val
    },
    error(val) {
      this.erroFlag = val
    },
  },
  methods: {
    validate(text) {
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](text ? text : this.text)
          if (!error || typeof error == 'string') {
            this.erroFlag = true
            this.errorMessage = error
            break
          } else {
            this.erroFlag = false
            this.errorMessage = ''
          }
        }
      }
      return !this.erroFlag
    },
    onInput(e) {
      this.text = e.target.value
      this.validate()
      this.$emit('input', this.text)
    },
    onBlur() {
      this.focus = false
    },
    onFocus() {
      this.focus = true
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-top: -24px;
  color: rgba(255, 255, 255, 0.87);
}
input {
  background: #25262c;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  padding: 0 16px;
  width: 100%;
  font-size: 14px;
  line-height: 44px;
  // caret-color: #3b77ff;
  color: rgba(255, 255, 255, 0.87);

  &:focus {
    outline: 1px solid var(--f-primary-90);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &.error-status {
    border: 1px solid #f9423a;
  }
}

.error-status-text {
  position: absolute;
  right: 0;
  margin-top: -17px;
  font-size: 10px;
  line-height: 14px;
  height: 14px;
  color: #f9423a;
}
</style>
