<template>
  <div class="footer-signin">
    <div>For business inquiry,</div>
    <a href="mailto:contact@alcherainc.com" target="_blank" class="email">contact@alcherainc.com</a>
    <div class="spacer-1"></div>
    <div>ⓒ 2022 Alchera Inc.</div>
    <!-- <div @click="$router.push('/signup')" class="email" style="margin-right: 30px">Sign Up</div> -->
    <img src="@/assets/PoweredLogo_white.svg" width="150px" style="margin-top: -2px; margin-left: 5px; margin-right: 30px" />
    <div>Cameras provided by</div>
    <img src="@/assets/wildfire-logo-white.png" width="110px" style="margin-left: 10px; margin-top: -15px" />
    <img src="@/assets/AlertCA_Logo_White.svg" width="132px" style="margin-left: 10px; margin-top: -15px" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer-signin {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
  margin: 0 30px 25px;

  .email {
    color: rgba(255, 255, 255, 0.87);
    text-decoration: none;
    margin-left: 8px;
    cursor: pointer;
  }
}
</style>
